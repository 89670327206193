<template>
    <section>
        <Header />
        <WAppIcon link="https://wa.link/n4y0hs" />
        <div class="container">
            <main class="principal">
                <picture class="only-pc aos">
                    <source srcset="../assets/landing/background_principal_pc.webp 2020w,
                    ../assets/landing/background_principal_pc1450.webp 1450w,
                    ../assets/landing/background_principal_pc1210.webp 1210w,
                    ../assets/landing/background_principal_pc900.webp 900w,
                    ../assets/landing/background_principal_pc380.webp 380w" type="image/webp" sizes="48.6vw">
                    <img src="../assets/landing/background_principal_pc.webp" 
                     srcset="../assets/landing/background_principal_pc.webp 2020w,
                    ../assets/landing/background_principal_pc1450.webp 1450w,
                    ../assets/landing/background_principal_pc1210.webp 1210w,
                    ../assets/landing/background_principal_pc900.webp 900w,
                    ../assets/landing/background_principal_pc380.webp 380w"
                    sizes="48.6vw" alt="Frame decorativo" loading="eager">
                </picture>
                <picture style="right: 8vw; top: 5vw; width: 34.60119791666666VW;" class="only-pc aos">
                    <source srcset="../assets/desarrollo-1.webp 785w,
                    ../assets/desarrollo-1-1310.webp 1310w,
                    ../assets/desarrollo-1-1040.webp 1040w,
                    ../assets/desarrollo-1-760.webp 760w,
                    ../assets/desarrollo-1-270.webp 270w
                    " sizes="34.59vw" type="image/webp">
                    <img src="../assets/desarrollo-1.webp" 
                    srcset="../assets/desarrollo-1.webp 785w,
                    ../assets/desarrollo-1-1310.webp 1310w,
                    ../assets/desarrollo-1-1040.webp 1040w,
                    ../assets/desarrollo-1-760.webp 760w,
                    ../assets/desarrollo-1-270.webp 270w" 
                    sizes="34.59vw" alt="Frame decorativo" loading="eager">
                </picture>
                <picture class="only-mov aos">
                    <source srcset="../assets/landing/background_principal_mov.webp 828w,
                    ../assets/landing/background_principal_mov-300.webp 300w,
                    ../assets/landing/background_principal_mov-760.webp 760w,
                    ../assets/landing/background_principal_mov-1070.webp 1070w,
                    ../assets/landing/background_principal_mov-1220.webp 1220w," sizes="100.22vw" type="image/webp">
                    <img src="../assets/landing/background_principal_mov.webp" 
                    srcset="../assets/landing/background_principal_mov.webp 828w,
                    ../assets/landing/background_principal_mov-300.webp 300w,
                    ../assets/landing/background_principal_mov-760.webp 760w,
                    ../assets/landing/background_principal_mov-1070.webp 1070w,
                    ../assets/landing/background_principal_mov-1220.webp 1220w,"
                    sizes="100.22vw" alt="Frame decorativo" loading="eager">
                </picture>
                <h1 class="aos">Agencia de desarrollo web </h1>
                <p class="aos">¿List@ para llevar a tu marca hacia el futuro? Únete al mundo digital y obtén tu página web a la medida <br><br>
                    En Legrafica somos expertos en diseñar y desarrollar tu sitio web de manera única y personalizada, esto para ayudar 
                    a impulsarte como empresario o emprendedor hacia el éxito en ventas y lo más importante, el posicionamiento 
                    de tu marca. 
                </p>
            </main>
            <section class="medio">
                <div class="aos medioContent only-pc blockImg">
                </div>
                <div class="aos medioContent">
                    <h2 class="medio__titulo">¿Por qué tener una Página Web para tu empresa?</h2>
                    <br>
                    <p>Tener una página web ofrece numerosas ventajas, entre las cuales destacan:</p>
                    <ul>
                        <li>
                            <p><strong>Notoriedad: </strong>Aumenta la visibilidad de tu marca y productos.</p>
                        </li>
                        <li>
                            <p><strong>Confianza: </strong>Genera credibilidad y profesionalismo ante tus clientes.</p>
                        </li>
                        <li>
                            <p><strong>Accesibilidad: </strong>Facilita que tus clientes te encuentren fácilmente,
                                aumentando así tus oportunidades de negocio.</p>
                        </li>
                        <li>
                            <p><strong>Rentabilidad: </strong>A mayor número de visitantes en tu sitio web, mayores son
                                las probabilidades de cerrar ventas y obtener un buen retorno de inversión.</p>
                        </li>
                    </ul>
                    <br><br>
                </div>
                <div class="aos medioContent">
                    <h2 class="medio__titulo">¿Cómo Aseguramos el Éxito de tu sitio?</h2>
                    <br>
                    <ul>
                        <li>
                            <p><strong>Diseño de Interfaz y Experiencia de Usuario (UI/UX): </strong>Creamos interfaces
                                intuitivas y atractivas que mejoran la interacción del usuario con tu sitio.</p>
                        </li>
                        <li>
                            <p><strong>Desarrollo de Código Fuente: </strong>Desarrollamos un código limpio y eficiente,
                                adaptado específicamente a tus necesidades.</p>
                        </li>
                        <li>
                            <p><strong>Optimización para Motores de Búsqueda (SEO): </strong>Realizamos una
                                investigación exhaustiva y aplicamos técnicas de SEO para asegurar que tu página se
                                posicione adecuadamente en los buscadores, atrayendo más tráfico y potenciales clientes.
                            </p>
                        </li>
                    </ul>
                    <br><br>
                </div>
                <div class="aos medioContent">
                    <h2 class="medio__titulo">Atención a cada detalle:</h2>
                    <br>
                    <p>Además de ser expertos en desarrollo web profesional, destacamos en el área de diseño de páginas
                        web, agregando creatividad y funcionalidad que nos distinguen. Esto agregará una experiencia de 
                        usuario placentera, inclinando la balanza a tu favor frente a la competencia.</p>
                    <br><br>

                    <h5>Trabajemos juntos, <a href="https://wa.link/n4y0hs">cotiza aquí</a></h5>
                </div>



            </section>
            <section class="proyectos">
                <h2 class="aos">Conoce algunos de <span>nuestros proyectos:</span></h2>
                <div class="proyectos__galeria">
                    <button class="aos" v-for="(project, index) in projects" :key="index"
                        @click="navAndDialog(project.link, project.imageWebp)" :aria-label="project.title">
                        <picture>
                            <source :srcset="project.imageWebp" type="image/webp">
                            <img :src="project.imagePng" :alt="project.title">
                        </picture>
                    </button>
                </div>
                <h2 id="contacto" class="aos">Trabajemos juntos, <span>cotiza aquí.</span></h2>
            </section>
            <form  class="aos" @submit.prevent="contactoLanding()">
                <label for="form__name">Nombre</label>
                <input type="text" id="form__name" v-model="name">
                <label for="form__email">Correo electrónico</label>
                <input type="email" id="form__email" v-model="email">
                <label for="form__phone">Teléfono</label>
                <input type="number" id="form__phone" v-model="phone">
                <label for="form__message">Mensaje</label>
                <textarea id="form__message" v-model="message"></textarea>

                <button type="submit">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="only-pc">
                        <circle cx="24" cy="24" r="24" fill="#987EF3" />
                        <g clip-path="url(#clip0_137_399)">
                            <path
                                d="M20.592 26.4954C20.4027 26.3414 20.2186 26.1848 20.0274 26.0399C18.3003 24.7329 16.5649 23.4382 14.8538 22.1116C14.6418 21.9478 14.5554 21.6188 14.4107 21.3669C14.6582 21.2636 14.9005 21.089 15.1558 21.0686C20.9931 20.5774 26.8324 20.0967 32.6719 19.6388C33.038 19.6102 33.4206 19.7784 33.796 19.855C33.6243 20.1794 33.5068 20.5536 33.273 20.8224C29.425 25.2399 25.5633 29.6462 21.6938 34.0434C21.5236 34.2358 21.2403 34.326 21.0093 34.4653C20.893 34.1991 20.6807 33.932 20.6759 33.6653C20.6347 31.5008 20.6337 29.3357 20.6171 27.1718C20.6153 26.9529 20.6011 26.7353 20.592 26.4954ZM31.0489 20.8108C31.0355 20.7584 31.0215 20.7081 31.0081 20.6558C26.0791 21.0602 21.1508 21.4659 16.2226 21.8716C17.6536 23.1375 19.115 24.2191 20.5455 25.3392C21.003 25.6977 21.3627 25.7561 21.8979 25.4782C24.7606 23.9967 27.6458 22.5629 30.523 21.1102C30.7038 21.0191 30.874 20.9103 31.0489 20.8108ZM31.4886 21.526C31.3506 21.4643 31.3252 21.4427 31.3123 21.4492C28.1515 23.03 24.99 24.6096 21.8352 26.2055C21.6905 26.2783 21.5217 26.4831 21.521 26.6295C21.5103 28.6332 21.5269 30.6395 21.5376 32.8818C24.9426 28.9959 28.1967 25.2828 31.4886 21.526Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_137_399">
                                <rect width="18" height="14.6667" fill="white"
                                    transform="translate(14.4043 21.3701) rotate(-26.7378)" />
                            </clipPath>
                        </defs>
                    </svg>

                    <p>
                        Enviar mensaje
                    </p>
                </button>

                <p class="form__status">{{ statusForm }}</p>
            </form>
            <dialog @click="closeModal()">
                
            </dialog>
        </div>
        <Footer :datos-footer="datosFooter" :landingWeb="true" />
    </section>
</template>
<script>
import Header from '../components/on-site/Header.vue';
import Footer from '../components/on-site/Footer.vue';
import WAppIcon from '../components/on-site/WAppIcon.vue';
export default {
    components: {
        Header,
        WAppIcon,
        Footer,
    },

    data() {
        return {
            name: '',
            email: '',
            phone: '',
            message: '',
            statusForm: '',
            imageSelected: null,
            projects: [
                {
                    imageWebp: require('../assets/landing/proyecto_15.webp'),
                    imagePng: require('../assets/landing/proyecto_15.png'),
                    link: '/development-post/alter',
                    title: "Alter"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_16.webp'),
                    imagePng: require('../assets/landing/proyecto_16.png'),
                    link: '/development-post/pcvirgo',
                    title: "PC Virgo"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_17.webp'),
                    imagePng: require('../assets/landing/proyecto_17.png'),
                    link: false,
                    title: "Sonora sin hambre"
                },
                {
                    imageWebp: require('../assets/landing/proyecto_18.webp'),
                    imagePng: require('../assets/landing/proyecto_18.png'),
                    link: false,
                    title: "Davis ind group"
                },
            ],
        }
    },

    metaInfo: {
        title: 'Agencia de desarrollo web',  
        meta: [
            {
                name: 'description',
                content: 'Nos encargamos del diseño y desarrollo de sitios web de manera personalizada que te ayudará a impulsarte como empresario o emprendedor hacia el éxito en ventas y posicionamiento de marca.'
            }
        ]
    },

    mounted() {
        let animateOnScroll = () => {
            const allAos = document.querySelectorAll(".aos");

            let allHaveVisibleClass = true;

            allAos.forEach((elemento, index) => {
                let { top, height, bottom } = elemento.getBoundingClientRect();

                if (window.getComputedStyle(elemento).display === 'none'
                    || bottom > 0 && bottom < window.innerHeight
                    || height >= window.innerHeight && top <= 0) {
                    elemento.classList.add("visible");
                }

                if (!elemento.classList.contains("visible")) {
                    allHaveVisibleClass = false;
                }

            });

            if (allHaveVisibleClass) window.removeEventListener('scroll', animateOnScroll)

        }

        window.addEventListener('scroll', animateOnScroll);
        animateOnScroll()
    },

    methods: {
        async contactoLanding() {

            if (this.name === '' || this.name === null || this.name === undefined) {
                this.statusForm = "Campo Nombre no válido";
                return ''
            }

            if (this.email === '' || this.email === null || this.email === undefined) {
                this.statusForm = "Campo Correo Electrónico no válido";
                return ''
            }

            if (this.phone === '' || this.phone === null || this.phone === undefined) {
                this.statusForm = "Campo Teléfono no válido";
                return ''
            }

            if (this.message === '' || this.message === null || this.message === undefined) {
                this.statusForm = "Campo Mensaje no válido";
                return ''
            }

            let data = {
                name: this.name,
                phone: this.phone,
                email: this.email,
                mensaje: this.message,
                from: "landing desarrollo web",
            };

            let response = await this.$store.dispatch(
                "admin/enviarMailContacto",
                data
            );

            if (response.status === 'success') {
                this.limpieza();
            } else {
                this.statusForm = "Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.";
            }
        },

        limpieza() {
            this.name = '';
            this.phone = '';
            this.email = '';
            this.message = '';

            this.statusForm = 'Mensaje enviado con éxito';

            setTimeout(() => {
                this.statusForm = '';
            }, 3000);
        },

        navAndDialog(link, image) {
            if (link) {
                window.location.href = link;
            } else {
                this.imageSelected = image;
                this.openModal();
            }
        },

        openModal() {
            const modal = document.querySelector("dialog");
            modal.showModal();
        },

        closeModal() {
            const modal = document.querySelector("dialog");
            this.imageSelected = null;
            modal.close();
        }
    },

}
</script>
<style scoped>
*,
*::after,
*::before {
    box-sizing: border-box;
}

h2,
p {
    margin: 0;
}

section {
    overflow: clip;
}

picture {
    display: block;
}

picture img {
    width: inherit;
    height: inherit;
    object-fit: contain;
}

.principal {
    position: relative;
}

.principal picture {
    position: absolute;
    top: 0;
}

.principal h1 {
    font-family: Gramatika-Medium;
    font-style: normal;
    font-weight: 500;
    color: #EF67C2;
    margin: 0;
}

.principal p {
    font-family: "IBM Plex Mono";
    font-weight: 400;
    font-style: normal;
    color: #000;
}


.medio p {
    font-family: "IBM Plex Mono";
    font-weight: 400;
    font-style: normal;
    color: #000;
}

.medio .medio__titulo {
    font-family: "IBM Plex Mono";
    font-weight: 700;
    font-style: normal;
    color: #000;
}


.medio>div:not(:last-of-type) {
    position: relative;
}

.medio>div::before {
    content: "";
    position: absolute;
    background-color: #FFB6EA;
}

.medio picture {
    width: 100%;
    height: 80vw;
}

.proyectos>h2:first-of-type {
    font-family: Gramatika-Medium;
    font-style: normal;
    font-weight: 500;
    color: #987EF3;
}

.proyectos>h2:first-of-type span {
    color: #FFB6EA;
}

.proyectos>h2:last-of-type {
    font-family: Gramatika-Medium;
    font-style: normal;
    font-weight: 500;
    color: #EF67C2;
}

.proyectos__galeria {
    display: grid;
}

.proyectos__galeria a picture {
    width: inherit;
    height: inherit;
}

.proyectos>h2:last-of-type span {
    color: #B2ACFC;
}

form {
    display: grid;
    width: 80vw;
    margin: auto;
}

label,
input,
textarea {
    color: #000;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 500;
}

input {
    border: 0;
    padding: 0;
}

textarea {
    border: 0;
    resize: none;
    padding: 0;
}

button[type='submit'] {
    border: 0;
    display: grid;
    align-items: center;
    padding: 0;
    cursor: pointer;
}


button[type='submit'] p {
    font-family: Gramatika-Bold !important;
    font-style: normal !important;
    font-weight: 400 !important;
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.proyectos__galeria button {
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
}

dialog {
    padding: 0;
    border: 0;
    display: flex;
}

dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}

@media (width < 768px),
(max-width: 767px) {

    .aos {
        opacity: 0;
        transform: translate(0, var(--traslado, -16.908212560386474VW));
        transition: opacity var(--visible, 0.6s) ease-out, transform var(--visible, 0.6s) ease-out;
    }

    .visible {
        opacity: 1;
        transform: translate(0, 0) !important;
    }

    .only-pc {
        display: none;
    }

    .principal {
        background-color: #E4E4F9;
        padding: 41.78743961352657VW 10.869565217391305VW 4.1062801932367154VW 12.80193236714976VW;
    }

    .principal picture {
        --traslado: 0;
        width: 100%;
        left: 0;
        --traslado: 0;
        transform: translate(100%, var(--traslado));
    }

    .principal h1 {
        font-size: 12.077294685990339VW;
        line-height: 109.3%;
        margin-right: 10.985507VW;
    }

    .principal p {
        margin: 17.874396135265698VW 0 0;
        font-size: 2.657004830917874VW;
        line-height: 180%;
    }

    .medio {
        padding: 144.44444444444443VW 10.144927536231885VW 8.695652173913043VW 14.492753623188406VW;
        position: relative;
        background: 
        url("../assets/landing/background_medio_web.webp"),
        linear-gradient(to bottom, #E4E4F9 0, #FFFFFF 72.94685990338165VW);
        background-repeat: no-repeat;
        background-size: 100vw auto;
        background-position: top center;
    }

    .medio>picture {
        width: 100%;
        position: absolute;
        top: 5.555555555555555VW;
        left: 0;
    }

    .medio>div::before {
        height: 0.4830917874396135VW;
        width: 10.869565217391305VW;
    }

    .medio>div:first-of-type::before {
        top: -9.420289855072465VW;
        left: 0;
    }

    .medio>div:nth-of-type(2)::before,
    .medio>div:nth-of-type(3)::before {
        top: -10.869565217391305VW;
        left: 0;
    }

    .medio p:not(.medio__titulo) {
        font-size: 2.657004830917874VW;
        line-height: 180%;
    }

    .medio__titulo {
        font-size: 3.3816425120772946VW;
        line-height: 180%;
    }

    .proyectos>h2:first-of-type {
        margin: 8.695652173913043VW 21.73913043478261VW 9.178743961352657VW 10.869565217391305VW;
        font-size: 10.869565217391305VW;
        line-height: 110.8%;
    }

    .proyectos__galeria {
        overflow: clip;
        display: grid;
        grid-template-columns: auto 1fr;
    }

    .proyectos__galeria picture {
        width: 100%;
        height: 100%;
    }

    .proyectos>h2:last-of-type {
        margin: 23.42995169082126VW 21.497584541062803VW 36.95652173913043VW 10.869565217391305VW;
        font-size: 10.869565217391305VW;
        line-height: 110.8%;
    }

    label,
    input,
    textarea {
        font-size: 2.898550724637681VW;
        line-height: 246.8%;
    }

    input {
        border-bottom: 0.24154589371980675VW solid #000;
        margin-bottom: 12.077294685990339VW;
        height: 7.246376811594203VW;
    }

    textarea {
        border-bottom: 0.24154589371980675VW solid #000;
        height: 14.492753623188406VW;
    }

    button[type='submit'] {
        background-color: #3702FB;
        width: 27.053140096618357VW;
        height: 27.053140096618357VW;
        grid-template-columns: auto;
        margin: 17.632850241545896VW auto 0;
        border-radius: 100%;
        padding: 0 3.6231884057971016VW;
    }

    button[type='submit'] p {
        color: #FFFFFF !important;
        font-size: 3.140096618357488VW !important;
        line-height: 106.8% !important;
    }

    .form__status {
        margin: 4.830917874396135VW 0 0;
        height: 4.830917874396135VW;
        text-align: center;
    }

    .proyectos__galeria {
        overflow: clip;
        row-gap: 1.6908212560386473VW;
    }

    .proyectos__galeria button img {
        object-fit: cover;
    }

    .proyectos__galeria button:nth-of-type(1) {
        grid-column: span 2;
        margin: 0 0 0 0;
        width: 69.80676328502415VW;
        height: 59.90338164251207VW;
        --traslado: 0;
        transform: translate(-100%, var(--traslado));
        aspect-ratio: 289 / 248;
    }

    .proyectos__galeria>button:nth-of-type(2) {
        grid-column: span 2;
        margin: 0 0 0 6.763285024154589VW;
        width: 83.33333333333334VW;
        height: 61.111111111111114VW;
        --traslado: 0;
        transform: translate(100%, var(--traslado));
        aspect-ratio: 345 / 253;
    }

    .proyectos__galeria>button:nth-of-type(3) {
        grid-column: span 2;
        margin: 0 0 0 19.32367149758454VW;
        width: 76.57004830917874VW;
        height: 68.84057971014492VW;
        --traslado: 0;
        transform: translate(-100%, var(--traslado));
        aspect-ratio: 317 / 285;
    }

    .proyectos__galeria>button:nth-of-type(4) {
        margin: 0 0 0 7.729468599033816VW;
        width: 42.99516908212561VW;
        height: 66.90821256038647VW;
        --traslado: 0;
        transform: translate(100%, var(--traslado));
        aspect-ratio: 178 / 277;
    }

    dialog {
        background-color: transparent;
        justify-content: center;
    }

    dialog img {
        width: 100%;
        height: auto;
    }

}

@media(width >=768px), (min-width: 768px) {

    .aos {
        opacity: 0;
        transform: translate(var(--traslado, 0, -5.208333333333334VW));
        transition: opacity var(--visible, 0.6s) ease-out, transform var(--visible, 0.6s) ease-out;
    }

    .visible {
        opacity: 1;
        transform: translate(0, 0);
    }

    .only-mov {
        display: none;
    }

    .principal {
        padding: 12.625VW 50.979167VW 10.479167VW 8.59375VW;
        background: linear-gradient(to bottom, #E4E4F9 34.166666666666664VW, #FFFFFF);
    }

    .principal picture {
        position: absolute;
        width: 48.604166666666664VW;
        aspect-ratio: 1097/1118;
        right: -5vw;
    }

    .principal h1 {
        width: 50.197916666666664VW;
        font-size: 7.552083333333333VW;
        line-height: 106%;
        margin: 0;
    }

    .principal p {
        margin: 3.0729166666666665VW 0 0;
        font-size: 0.8333333333333334VW;
        line-height: 180%;
    }

    .blockImg{
        background-image: url("../assets/blockImgLanding.webp");
        background-repeat: no-repeat;
        background-size: 34.84375VW auto;
    }

    .medio {
        padding: 4.5VW 11.458333333333332VW 9.739583333333333VW 11.458333333vw;
        background-size: 44.479166666666664VW 48.333333333333336VW;
        background-position: left top;
        background-repeat: no-repeat;
        background-color: #FFF;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 5vw;
    }

    .medio>div::before {
        height: 0.10416666666666667VW;
        width: 3.125VW;
    }

    .medio>div:first-of-type::before {
        top: -1.7187500000000002VW;
        left: -4.84375VW;
    }

    .medio>div:nth-of-type(2)::before,
    .medio>div:nth-of-type(3)::before,
    .medio>div:nth-of-type(4)::before {
        top: -1.9791666666666665VW;
        left: -5.104166666666667VW;
    }

    .medio p:not(.medio__titulo) {
        font-size: 0.78125VW;
        line-height: 180%;
    }

    .medio__titulo {
        font-size: 1.0416666666666665VW;
        line-height: 180%;
    }

    .medioContent {
        width: 34.84375VW;
    }

    .medioContent picture {
        width: 34.84375VW;
        height: 25.005520833333332VW;
        object-fit: contain;
    }

    .medioContent h5 {
        font-weight: 500;
        text-align: right;
        margin: 3.0729166666666665VW 0 0;
        font-size: 1.1333333333333334VW;
        color: #EF67C2;


    }
    .medioContent h5 a{
        text-decoration: underline;
        color: #B2ACFC;
    }

    .proyectos>h2:first-of-type {
        width: 52.041667VW;
        margin: 4.739583333333333VW 0 6.666666666666667VW 9.635416666666668VW;

        font-size: 5.208333333333334VW;
        line-height: 104.3%;
    }

    .proyectos__galeria {
        grid-template-columns: repeat(3, auto);
        justify-content: center;
    }

    .proyectos__galeria button {
        overflow: clip;
    }

    .proyectos__galeria button picture {
        transition: 0.6s ease-out;
        width: 100%;
        height: 100%;
    }

    .proyectos__galeria button:hover picture {
        transform: scale(1.2);
    }

    .proyectos__galeria button:nth-of-type(1) {
        margin: 0.26041666666666663VW 0 0 0;
        width: 32.447916666666664VW;
        height: 23.333333333333332VW;
        aspect-ratio: 623 / 448;
    }

    .proyectos__galeria>button:nth-of-type(2) {
        margin: 0 0 2.083333333333333VW 2.604166666666667VW;
        width: 43.48958333333333VW;
        height: 27.65625VW;
        aspect-ratio: 835 / 531;
        grid-column: span 2;
    }

    .proyectos__galeria>button:nth-of-type(3) {
        margin: -3.6458333333333335VW 0 0 1.40625VW;
        width: 21.71875VW;
        height: 27.864583333333332VW;
        aspect-ratio: 417 / 535;
    }

    .proyectos__galeria>button:nth-of-type(4) {
        margin: 0 0 0 0;
        width: 19.6875VW;
        height: 24.21875VW;
        aspect-ratio: 378/465;
        grid-column: 3 / 4;
    }

    .proyectos>h2:last-of-type {
        width: 50VW;
        margin: 9.583333333333334VW 0 6.135416666666666VW 9.583333333333334VW;

        font-size: 5.208333333333334VW;
        line-height: 104.3%;
    }

    form {
        --traslado: 100%, 0;
        width: 50vw;
        margin-right: auto;
        margin-left: 10vw;

    }

    label,
    input,
    textarea {
        font-size: 0.7291666666666666VW;
        line-height: 246.8%;
    }


    input {
        border-bottom: 0.052083333333333336VW solid #000;
        margin-bottom: 2.864583333333333VW;
        height: 1.8229166666666667VW;
    }

    textarea {
        border-bottom: 0.052083333333333336VW solid #000;
        height: 4.166666666666666VW;
    }

    button[type='submit'] {
        background-color: transparent;
        color: #000;
        width: 9.835416666666668VW;
        grid-template-columns: auto 1fr;
        column-gap: 1.0416666666666665VW;
        margin: 3.4375000000000004VW 0 0;
    }

    button[type='submit'] p {
        font-size: 0.8333333333333334VW !important;
        line-height: 152.3% !important;
    }

    .form__status {
        margin: 1.0416666666666665VW 0 0;
        height: 1.5625VW;
    }

    dialog img {
        width: 100%;
        object-fit: cover;
        background-color: transparent;
    }

}
</style>